import * as React from 'react';

import Seo from '../../components/seo';
import PersistError from '../../components/persistError';
import Loader from '../../components/loader';
import { useCallback, useEffect, useState } from 'react';
import axios from 'axios';

const VerifyPage = ({ uuid }: any) => {
  const [stage, setStage] = useState<string>('loading');
  const [remove, setRemove] = useState<boolean>(false);

  useEffect(() => {
    (async () => {
      const remove = await axios.post(`/api/${uuid}/remove-status`);
      setRemove(remove.data);
      setStage('initial');
    })();
  }, []);

  const handleRemove = useCallback(
    async (remove: boolean) => {
      setStage('loading');

      try {
        await axios.post(`/api/remove`, { uuid, remove });
        setStage('success');
      } catch (error) {
        setStage('error');
      }
    },
    [setStage],
  );

  return (
    <div className="container mx-auto py-6 px-4">
      <Seo title="Abmelden" description="Abmelden" />
      {stage === 'loading' && <Loader text="Kleinen Augenblick..." />}
      {stage === 'error' && <PersistError />}
      {stage === 'initial' && (
        <>
          <p>
            {remove
              ? 'Willst du dich wieder anmelden?'
              : 'Willst du dich wirklich abmelden?'}
          </p>
          <br />
          <button
            className="px-6 py-2 rounded border bg-teal-600 text-white hover:bg-neutral-100 hover:text-neutral-800 transition duration-300"
            onClick={() => handleRemove(!remove)}
          >
            {remove ? 'Anmelden' : 'Abmelden'}
          </button>
        </>
      )}
      {stage === 'success' && (
        <>
          <h2>
            {remove
              ? 'Du wurdest erfolgreich angemeldet!'
              : 'Du wurdest erfolgreich abgemeldet!'}
          </h2>
        </>
      )}
    </div>
  );
};

export default VerifyPage;
